import { useOutletContext } from 'react-router-dom';
import { Box, Divider, Grid, GridItem, Heading, Text } from '@chakra-ui/react';

import MarkdownDisplay from '../../components/MarkdownDisplay';
import i18n from '../../utils/i18n';

import SummaryDemoCard from './components/SummaryDemoCard';
import { AdBoardOutletContextType } from './AdBoardDetailsWrapper';

const ExecutiveSummary = () => {
  const { adBoardData } = useOutletContext<AdBoardOutletContextType>();

  return (
    <>
      <Grid templateColumns="repeat(5, 1fr)" gap={6} mt={4}>
        <GridItem w="100%">
          <SummaryDemoCard
            title={i18n.t('adBoardDetails.executiveSummary.attendees')}
            value={adBoardData.participants?.length || 0}
          />
        </GridItem>
        <GridItem w="100%">
          <SummaryDemoCard
            title={i18n.t('adBoardDetails.executiveSummary.completionRate')}
            value={'100%'}
          />
        </GridItem>
      </Grid>
      <Text mt={8}>
        Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
        suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis
        autem vel eum iure reprehenderit qui in ea voluptate velit esse quam
        nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
        voluptas nulla pariatur. Ut enim ad minima veniam, quis nostrum
        exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea
        commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea
        voluptate velit esse quam nihil molestiae consequatur, vel illum qui
        dolorem eum fugiat quo voluptas nulla pariatur
      </Text>
      <Heading mt={10} mb={2} size={'sm'}>
        Recommendations
      </Heading>
      <Divider />
      <Box mt={8}>
        <MarkdownDisplay
          source={`Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?`}
        />
      </Box>
    </>
  );
};

export default ExecutiveSummary;
