/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Analysis } from '../models';
import { BulkUpdateRespondentDemographicsDto } from '../models';
import { ComparableEngagements } from '../models';
import { CreateSurveyEngagementDto } from '../models';
import { EngageAISendMessageDto } from '../models';
import { MessageDto } from '../models';
import { Question } from '../models';
import { Response } from '../models';
import { SurveyEngagement } from '../models';
import { SurveyEngagementDetailsDto } from '../models';
import { UpdateSurveyEngagementDto } from '../models';
/**
 * SurveyEngagementsApi - axios parameter creator
 * @export
 */
export const SurveyEngagementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Refresh the survey sparrow data for the engagement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshSurveyControllerRefreshSurvey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshSurveyControllerRefreshSurvey.');
            }
            const localVarPath = `/api/survey-engagements/{id}/refresh-survey`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk updates a Survey Engagement's Respondent Demographics
         * @param {BulkUpdateRespondentDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerBulkUpdateDemographics: async (body: BulkUpdateRespondentDemographicsDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling surveyEngagementControllerBulkUpdateDemographics.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerBulkUpdateDemographics.');
            }
            const localVarPath = `/api/survey-engagements/{id}/respondent-demographics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Survey Engagement
         * @param {CreateSurveyEngagementDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerCreate: async (body: CreateSurveyEngagementDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling surveyEngagementControllerCreate.');
            }
            const localVarPath = `/api/survey-engagements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Survey Engagements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/survey-engagements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerFindOne.');
            }
            const localVarPath = `/api/survey-engagements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Analysis for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerGetAnalysis: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerGetAnalysis.');
            }
            const localVarPath = `/api/survey-engagements/{id}/analysis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the ComparableEngagements for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerGetComparableEngagements: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerGetComparableEngagements.');
            }
            const localVarPath = `/api/survey-engagements/{id}/comparable-engagements`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of messages for a specific OpenAI thread.        Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.        Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerGetEngageAIMessages: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerGetEngageAIMessages.');
            }
            const localVarPath = `/api/survey-engagements/{id}/ai-session`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the EngagementDetails for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerGetEngagementDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerGetEngagementDetails.');
            }
            const localVarPath = `/api/survey-engagements/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Questions for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerGetQuestions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerGetQuestions.');
            }
            const localVarPath = `/api/survey-engagements/{id}/questions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Responses for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerGetResponses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerGetResponses.');
            }
            const localVarPath = `/api/survey-engagements/{id}/responses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a message to the Engagement's AI chat session for the current user
         * @param {EngageAISendMessageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerSendEngageAIMessage: async (body: EngageAISendMessageDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling surveyEngagementControllerSendEngageAIMessage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerSendEngageAIMessage.');
            }
            const localVarPath = `/api/survey-engagements/{id}/ai-session`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Survey Engagement by its ID
         * @param {UpdateSurveyEngagementDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyEngagementControllerUpdate: async (body: UpdateSurveyEngagementDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling surveyEngagementControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyEngagementControllerUpdate.');
            }
            const localVarPath = `/api/survey-engagements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyEngagementsApi - functional programming interface
 * @export
 */
export const SurveyEngagementsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Refresh the survey sparrow data for the engagement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshSurveyControllerRefreshSurvey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).refreshSurveyControllerRefreshSurvey(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Bulk updates a Survey Engagement's Respondent Demographics
         * @param {BulkUpdateRespondentDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerBulkUpdateDemographics(body: BulkUpdateRespondentDemographicsDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyEngagement>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerBulkUpdateDemographics(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a Survey Engagement
         * @param {CreateSurveyEngagementDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerCreate(body: CreateSurveyEngagementDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyEngagement>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all Survey Engagements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<SurveyEngagement>>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyEngagement>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Analysis for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetAnalysis(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Analysis>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerGetAnalysis(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the ComparableEngagements for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetComparableEngagements(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<ComparableEngagements>>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerGetComparableEngagements(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns the list of messages for a specific OpenAI thread.        Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.        Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetEngageAIMessages(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<MessageDto>>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerGetEngageAIMessages(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the EngagementDetails for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetEngagementDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyEngagementDetailsDto>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerGetEngagementDetails(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Questions for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetQuestions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Question>>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerGetQuestions(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Responses for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetResponses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Response>>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerGetResponses(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sends a message to the Engagement's AI chat session for the current user
         * @param {EngageAISendMessageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerSendEngageAIMessage(body: EngageAISendMessageDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerSendEngageAIMessage(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a Survey Engagement by its ID
         * @param {UpdateSurveyEngagementDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerUpdate(body: UpdateSurveyEngagementDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyEngagement>>> {
            const localVarAxiosArgs = await SurveyEngagementsApiAxiosParamCreator(configuration).surveyEngagementControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyEngagementsApi - factory interface
 * @export
 */
export const SurveyEngagementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Refresh the survey sparrow data for the engagement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshSurveyControllerRefreshSurvey(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return SurveyEngagementsApiFp(configuration).refreshSurveyControllerRefreshSurvey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk updates a Survey Engagement's Respondent Demographics
         * @param {BulkUpdateRespondentDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerBulkUpdateDemographics(body: BulkUpdateRespondentDemographicsDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyEngagement>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerBulkUpdateDemographics(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Survey Engagement
         * @param {CreateSurveyEngagementDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerCreate(body: CreateSurveyEngagementDto, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyEngagement>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Survey Engagements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<SurveyEngagement>>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyEngagement>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Analysis for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetAnalysis(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Analysis>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerGetAnalysis(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the ComparableEngagements for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetComparableEngagements(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ComparableEngagements>>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerGetComparableEngagements(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of messages for a specific OpenAI thread.        Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.        Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetEngageAIMessages(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<MessageDto>>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerGetEngageAIMessages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the EngagementDetails for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetEngagementDetails(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyEngagementDetailsDto>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerGetEngagementDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Questions for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetQuestions(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Question>>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerGetQuestions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Responses for a Survey Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerGetResponses(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Response>>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerGetResponses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a message to the Engagement's AI chat session for the current user
         * @param {EngageAISendMessageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerSendEngageAIMessage(body: EngageAISendMessageDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerSendEngageAIMessage(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Survey Engagement by its ID
         * @param {UpdateSurveyEngagementDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyEngagementControllerUpdate(body: UpdateSurveyEngagementDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyEngagement>> {
            return SurveyEngagementsApiFp(configuration).surveyEngagementControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyEngagementsApi - object-oriented interface
 * @export
 * @class SurveyEngagementsApi
 * @extends {BaseAPI}
 */
export class SurveyEngagementsApi extends BaseAPI {
    /**
     * 
     * @summary Refresh the survey sparrow data for the engagement
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async refreshSurveyControllerRefreshSurvey(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return SurveyEngagementsApiFp(this.configuration).refreshSurveyControllerRefreshSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Bulk updates a Survey Engagement's Respondent Demographics
     * @param {BulkUpdateRespondentDemographicsDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerBulkUpdateDemographics(body: BulkUpdateRespondentDemographicsDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyEngagement>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerBulkUpdateDemographics(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a Survey Engagement
     * @param {CreateSurveyEngagementDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerCreate(body: CreateSurveyEngagementDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyEngagement>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all Survey Engagements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<SurveyEngagement>>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a Survey Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyEngagement>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Analysis for a Survey Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerGetAnalysis(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Analysis>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerGetAnalysis(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the ComparableEngagements for a Survey Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerGetComparableEngagements(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ComparableEngagements>>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerGetComparableEngagements(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns the list of messages for a specific OpenAI thread.        Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.        Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerGetEngageAIMessages(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<MessageDto>>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerGetEngageAIMessages(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the EngagementDetails for a Survey Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerGetEngagementDetails(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyEngagementDetailsDto>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerGetEngagementDetails(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Questions for a Survey Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerGetQuestions(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Question>>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerGetQuestions(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Responses for a Survey Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerGetResponses(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Response>>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerGetResponses(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Sends a message to the Engagement's AI chat session for the current user
     * @param {EngageAISendMessageDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerSendEngageAIMessage(body: EngageAISendMessageDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerSendEngageAIMessage(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates a Survey Engagement by its ID
     * @param {UpdateSurveyEngagementDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyEngagementsApi
     */
    public async surveyEngagementControllerUpdate(body: UpdateSurveyEngagementDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyEngagement>> {
        return SurveyEngagementsApiFp(this.configuration).surveyEngagementControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
