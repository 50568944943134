// External Dependencies
import { memo, useCallback, useMemo } from 'react';
import { FiCalendar, FiUser } from 'react-icons/fi';
import {
  Badge,
  Box,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
} from '@chakra-ui/react';
import { SurveyEngagementDetailsDto } from '@keyops-cep/api-client';

import ChartCard from '../../components/Visualization/ChartCard/';
import { DEMOGRAPHIC_NAMES } from '../../utils/constants/visualization-constants';
import i18n from '../../utils/i18n';

import DownloadButton from './components/DownloadButton';

export const KPI_DEMOGRAPHICS = [
  'specialty',
  'practiceSetting',
  'provinceOfPractice',
] as (keyof typeof DEMOGRAPHIC_NAMES)[];

const specialtyCardText = i18n.t('engagementDetails.kpiSection.specialty');
const practiceSettingsCardText = i18n.t(
  'engagementDetails.kpiSection.practice-settings'
);
const regionalDistributionCardText = i18n.t(
  'engagementDetails.kpiSection.regional-distribution'
);

const getDemographicLabel = (demographic: string) => {
  switch (demographic) {
    case 'specialty':
      return specialtyCardText;
    case 'practiceSetting':
      return practiceSettingsCardText;
    case 'provinceOfPractice':
      return regionalDistributionCardText;
    default:
      return '';
  }
};

interface KPISectionProps {
  engagementDetails: SurveyEngagementDetailsDto;
}

const KPISection = ({ engagementDetails }: KPISectionProps): JSX.Element => {
  const averageYearsOfExperience = useMemo(() => {
    return engagementDetails.aggregateRespondentDemographics.yearsOfExperience;
  }, [engagementDetails]);

  const kpiDemographicsVisual = useCallback(() => {
    return KPI_DEMOGRAPHICS.filter(
      (ele): ele is Exclude<typeof ele, 'licenseYear'> => ele !== 'licenseYear'
    ).map((ele) => {
      const label = getDemographicLabel(ele);
      return (
        <ChartCard
          key={ele}
          cardLabel={label}
          data={engagementDetails.aggregateRespondentDemographics?.[ele] ?? {}}
        />
      );
    });
  }, [engagementDetails.aggregateRespondentDemographics]);

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem as={Card} h={85} p={4}>
          <Flex h={'100%'} w={'100%'}>
            <Box my={'auto'}>
              <Badge
                borderRadius={'full'}
                p={3}
                colorScheme={'gray'}
                display={{ base: 'none', md: 'block' }}
              >
                <Center>
                  <Icon
                    aria-label="completed-icon"
                    boxSize={6}
                    color={'keyops.blue'}
                    as={FiCalendar}
                  />
                </Center>
              </Badge>
            </Box>
            <Box flex={1} px={'5'} my={'auto'}>
              <Flex justifyContent={'space-between'}>
                <Box>
                  <Text className="kpiHeaderText">
                    {i18n.t('engagementDetails.kpiSection.startDate')}
                  </Text>
                  <Text className="kpiText">
                    {engagementDetails.startDate
                      ? new Date(
                          engagementDetails.startDate
                        ).toLocaleDateString('en-us', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })
                      : 'N/A'}
                  </Text>
                </Box>
                <Center>
                  <Divider orientation="vertical" />
                </Center>
                <Box>
                  <Text className="kpiHeaderText">
                    {i18n.t('engagementDetails.kpiSection.closedDate')}
                  </Text>
                  <Text className="kpiText">
                    {engagementDetails.endDate
                      ? new Date(engagementDetails.endDate).toLocaleDateString(
                          'en-us',
                          {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }
                        )
                      : 'N/A'}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            <GridItem as={Card} colSpan={2} h={85} p={4}>
              <Flex h={'100%'} w={'100%'}>
                <Box my={'auto'}>
                  <Badge
                    borderRadius={'full'}
                    p={3}
                    colorScheme={'gray'}
                    display={{ base: 'none', md: 'block' }}
                  >
                    <Center>
                      <Icon
                        aria-label="completed-icon"
                        boxSize={6}
                        color={'keyops.blue'}
                        as={FiUser}
                      />
                    </Center>
                  </Badge>
                </Box>
                <Box flex={1} px={'5'} my={'auto'}>
                  <Flex justifyContent={'space-between'}>
                    <Box>
                      <Text className="kpiHeaderText">
                        {i18n.t('engagementDetails.kpiSection.respondents')}
                      </Text>
                      <Text className="kpiText">
                        {engagementDetails.responseCount}/
                        {engagementDetails.responseCountTarget}
                      </Text>
                    </Box>
                    <Center>
                      <Divider orientation="vertical" />
                    </Center>
                    <Box>
                      <Text className="kpiHeaderText">
                        {i18n.t('engagementDetails.kpiSection.avgExperience')}
                      </Text>
                      <Text className="kpiText">
                        {averageYearsOfExperience}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </GridItem>
            <GridItem as={Center} h={85}>
              <DownloadButton engagementData={engagementDetails} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={6}>
        {kpiDemographicsVisual()}
      </Grid>
    </>
  );
};

export default memo(KPISection);
