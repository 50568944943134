export const dummyAdBoard = {
  id: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
  title: 'New ad board title - update',
  name: 'New ad board title - update',
  displayTitle: 'New ad board title - update',
  description: 'new description - 1',
  objectives: '',
  startDate: new Date('2024-08-1T14:20:31.467Z'),
  endDate: new Date('2025-08-16T14:20:31.467Z'),
  companyId: 23,
  generalDiscussionId: '3f550a3b-8a5c-42b8-9e56-54796a19d06b',
  moderators: null,
  resultsStatus: 'test-InsightsAvailable',
  state: 'live',
  createdAt: '2024-08-12T19:57:18.950Z',
  updatedAt: '2024-08-14T22:20:30.555Z',
  company: {
    id: 23,
    name: 'GSK',
    createdAt: '2022-09-08T00:33:46.966Z',
    updatedAt: '2022-09-08T00:33:46.966Z',
  },
  sectionGroups: [
    {
      id: 'ee6833e0-2df3-4cb7-8678-354107299ba4',
      title: 'Main',
      description: null,
      index: 0,
      adBoardId: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
      estimatedTime: 3600,
      createdAt: '2024-09-19T06:57:57.477Z',
      updatedAt: '2024-09-19T06:57:57.477Z',
      sections: [
        {
          id: '422d257c-2164-46f8-82ea-54493e090518',
          title: 'Titled 1',
          description: 'Description added',
          index: 0,
          type: 'video',
          adBoardId: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
          sectionGroupId: 'ee6833e0-2df3-4cb7-8678-354107299ba4',
          createdAt: '2024-08-15T16:18:45.087Z',
          updatedAt: '2024-08-19T15:25:12.904Z',
          content: {
            id: '422d257c-2164-46f8-82ea-54493e090518',
            videoId: 'e0bbd2e3-c2ce-4d06-bc5e-dfd7c84c5dd0',
            video: {
              id: 'e0bbd2e3-c2ce-4d06-bc5e-dfd7c84c5dd0',
              title:
                'Video title is too long to display on the screen now it is getting too big, need to fix this prroblem.',
              createdAt: '2024-08-16T11:41:26.965Z',
              updatedAt: '2024-08-28T19:52:40.828Z',
              description: 'Video description ',
              link: 'https://vimeo.com/999362995',
              uri: '/videos/999362995',
              totalRunTime: 11,
            },
          },
        },
        {
          id: '2f701e44-25e8-405b-9b10-3d9907c8d37b',
          title:
            'long name for section title, it should look fine in the future it will be fixed for sure',
          description: 'sample description',
          index: 1,
          type: 'attachment',
          adBoardId: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
          sectionGroupId: 'ee6833e0-2df3-4cb7-8678-354107299ba4',
          createdAt: '2024-08-28T19:55:35.001Z',
          updatedAt: '2024-08-28T23:12:09.343Z',
          content: [
            {
              id: '2f701e44-25e8-405b-9b10-3d9907c8d37b',
              attachmentId: 'c631800d-0dae-4d79-9cdc-d7de2ea6f908',
              index: 0,
              attachment: {
                id: 'c631800d-0dae-4d79-9cdc-d7de2ea6f908',
                title: 'sampledocument',
                description: null,
                contentType: 'application/pdf',
                readingTime: '1',
                createdAt: '2024-08-28T19:59:01.373Z',
                updatedAt: '2024-08-28T19:59:01.374Z',
              },
            },
            {
              id: '2f701e44-25e8-405b-9b10-3d9907c8d37b',
              attachmentId: 'adbaa25d-f317-40a0-9eeb-0ca0635ad442',
              index: 1,
              attachment: {
                id: 'adbaa25d-f317-40a0-9eeb-0ca0635ad442',
                title: 'dictionary',
                description: null,
                contentType: 'application/pdf',
                readingTime: '10000',
                createdAt: '2024-08-28T23:11:55.256Z',
                updatedAt: '2024-08-28T23:11:55.256Z',
              },
            },
            {
              id: '2f701e44-25e8-405b-9b10-3d9907c8d37b',
              attachmentId: '8a3a11c9-82b1-465f-93e0-fa3f3944b0f4',
              index: 2,
              attachment: {
                id: '8a3a11c9-82b1-465f-93e0-fa3f3944b0f4',
                title: 'Sample Document',
                description: 'This is just a sample document',
                contentType: 'application/pdf',
                readingTime: '60',
                createdAt: '2024-08-29T00:18:01.146Z',
                updatedAt: '2024-08-29T00:18:01.146Z',
              },
            },
          ],
        },
        {
          id: '524d82a6-0add-4615-a1e9-5226ddc700f6',
          title: 'Untitled activity-1',
          description: null,
          index: 2,
          type: 'attachment',
          adBoardId: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
          sectionGroupId: 'ee6833e0-2df3-4cb7-8678-354107299ba4',
          createdAt: '2024-09-20T14:22:45.488Z',
          updatedAt: '2024-09-20T14:22:45.488Z',
          content: [
            {
              id: '524d82a6-0add-4615-a1e9-5226ddc700f6',
              attachmentId: 'f5299ce4-a967-4424-91aa-a03a74e82a9b',
              index: 0,
              attachment: {
                id: 'f5299ce4-a967-4424-91aa-a03a74e82a9b',
                title: 'new one',
                description: null,
                contentType: 'application/pdf',
                readingTime: '111',
                createdAt: '2024-09-20T14:23:00.462Z',
                updatedAt: '2024-09-20T14:23:00.462Z',
              },
            },
          ],
        },
      ],
    },
    {
      id: 'b1be96ad-a45d-473f-bd93-a9ea4e7ab66c',
      title: 'New 2',
      description: '',
      index: 1,
      adBoardId: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
      estimatedTime: 2000,
      createdAt: '2024-09-20T14:23:13.537Z',
      updatedAt: '2024-09-20T14:23:13.537Z',
      sections: [
        {
          id: '7c2e893f-b98a-41b0-bac1-52c1cca9c36a',
          title: 'Untitled activity',
          description: null,
          index: 0,
          type: 'attachment',
          adBoardId: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
          sectionGroupId: 'b1be96ad-a45d-473f-bd93-a9ea4e7ab66c',
          createdAt: '2024-09-20T14:23:16.789Z',
          updatedAt: '2024-09-20T14:23:16.789Z',
          content: [
            {
              id: '7c2e893f-b98a-41b0-bac1-52c1cca9c36a',
              attachmentId: '42726d1a-1ef5-4575-9235-17f2d8a653d7',
              index: 0,
              attachment: {
                id: '42726d1a-1ef5-4575-9235-17f2d8a653d7',
                title: '123 new document',
                description: null,
                contentType: 'application/pdf',
                readingTime: '100',
                createdAt: '2024-09-20T14:23:31.632Z',
                updatedAt: '2024-09-20T14:23:31.632Z',
              },
            },
          ],
        },
      ],
    },
  ],
  participants: [
    {
      id: 'fb27206d-e560-49f0-a4a4-794d9a114d41',
      userId: 804406,
      inviteId: 'a444e855-e193-4ace-b119-fa0c2218041a',
      adBoardId: 'eafd6f6f-2ff9-4c90-9e88-925162e63385',
      honorarium: 1000,
      createdAt: '2024-09-06T02:35:29.179Z',
      updatedAt: '2024-09-06T02:35:29.179Z',
      progress: {
        id: 'fb27206d-e560-49f0-a4a4-794d9a114d41',
        latestSection: '422d257c-2164-46f8-82ea-54493e090518',
        progressDetails: {
          sectionProgress: [
            {
              sectionId: '422d257c-2164-46f8-82ea-54493e090518',
              finished: true,
              accessible: true,
              currentTime: 11.011,
            },
            {
              sectionId: '2f701e44-25e8-405b-9b10-3d9907c8d37b',
              finished: false,
              accessible: true,
            },
          ],
        },
        version: '1',
        startedOn: null,
        finishedOn: null,
        createdAt: '2024-09-06T02:35:29.183Z',
        updatedAt: '2024-09-20T14:23:54.224Z',
      },
      completionPercentage: 25,
    },
  ],
};
