import { Outlet } from 'react-router-dom';

import BackToHome from './components/BackToHome';
import { dummyAdBoard } from './DummyAdBoard';

export type AdBoardOutletContextType = {
  adBoardData: {
    id: string;
    name: string;
    displayTitle: string;
    startDate: Date;
    endDate: Date;
    description: string;
    objectives: string;
    resultsStatus: string;
    state: string;
    sectionGroups?: unknown;
    participants: Array<unknown>;
    moderators?: unknown;
  };
};

const AdBoardDetailsWrapper = () => {
  // TODO: get correct data for ad-board here and pass it to context
  return (
    <>
      <BackToHome />
      <Outlet
        context={
          { adBoardData: dummyAdBoard } satisfies AdBoardOutletContextType
        }
      />
    </>
  );
};

export default AdBoardDetailsWrapper;
